import { useState, useEffect } from "react";
export var useDeviceWidth = function () {
    var _a = useState(window.innerWidth), width = _a[0], setWidth = _a[1];
    useEffect(function () {
        var handleResize = function () { return setWidth(window.innerWidth); };
        window.addEventListener('resize', handleResize);
        return function () {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return width;
};
